import { ContentObserver } from '@angular/cdk/observers';
import { isPlatformBrowser } from '@angular/common';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  Output,
  PLATFORM_ID,
  Renderer2,
  SkipSelf,
} from '@angular/core';
import { TimeUtils } from '@freelancer/time-utils';
import { isDefined } from '@freelancer/utils';
import { Subscription, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MaxLinesHelper } from './max-lines.helper';
import {
  FontColor,
  FontStyle,
  FontType,
  FontWeight,
  ReadMore,
  ReadMoreColor,
  TextAlign,
  TextSize,
  TextTransform,
} from './text.types';

@Component({
  selector: 'fl-text',
  template: `
    <ng-container [ngSwitch]="fontType">
      <div
        *ngSwitchCase="FontType.PARAGRAPH"
        class="NativeElement"
        role="paragraph"
        [attr.data-text-transform]="textTransform"
        [attr.data-text-decoration]="textDecoration"
        [attr.data-color]="color"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-large]="sizeDesktopLarge"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-style]="fontStyle"
        [attr.data-line-break]="displayLineBreaks"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </div>
      <span
        *ngSwitchCase="FontType.SPAN"
        class="NativeElement Span"
        [attr.data-text-transform]="textTransform"
        [attr.data-text-decoration]="textDecoration"
        [attr.data-color]="color"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-large]="sizeDesktopLarge"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-style]="fontStyle"
        [attr.data-line-break]="displayLineBreaks"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </span>
      <strong
        *ngSwitchCase="FontType.STRONG"
        class="NativeElement Strong"
        [attr.data-text-transform]="textTransform"
        [attr.data-text-decoration]="textDecoration"
        [attr.data-color]="color"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-large]="sizeDesktopLarge"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-style]="fontStyle"
        [attr.data-line-break]="displayLineBreaks"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </strong>
      <!-- this one's also a div but doesn't have paragraph semantics -->
      <div
        *ngSwitchCase="FontType.CONTAINER"
        class="NativeElement"
        [attr.data-text-transform]="textTransform"
        [attr.data-text-decoration]="textDecoration"
        [attr.data-color]="color"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-large]="sizeDesktopLarge"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-style]="fontStyle"
        [attr.data-line-break]="displayLineBreaks"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </div>
      <ng-template #injectedContent>
        <ng-content></ng-content>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent
  implements AfterViewInit, OnDestroy, OnChanges, OnInit
{
  FontType = FontType;

  @Input()
  @HostBinding('attr.data-color')
  color = FontColor.FOREGROUND;

  /**
   * Font size for mobile and above
   */
  @Input() size: TextSize = TextSize.XSMALL;

  /**
   * Change the [size] from tablet and above
   */
  @Input() sizeTablet?: TextSize;

  /**
   * Change the [size] and/or [sizeTablet] from desktop and above
   */
  @Input() sizeDesktop?: TextSize;

  /** Change the [size], [sizeTablet] and [sizeDesktop] from desktop-large and above */
  @Input() sizeDesktopLarge?: TextSize;

  /** Change the [size], [sizeTablet], [sizeDesktop] and [sizeDesktopLarge] from desktop-xlarge and above */
  @Input() sizeDesktopXLarge?: TextSize;

  /** Change the [size], [sizeTablet], [sizeDesktop], [sizeDesktopLarge] and [sizeDesktopXLarge] from desktop-xxlarge and above */
  @Input() sizeDesktopXXLarge?: TextSize;

  @Input() fontStyle = FontStyle.NORMAL;

  /** Change the text-align property (only works on fontType.PARAGRAPH) */
  @HostBinding('attr.data-text-align')
  @Input()
  textAlign?: TextAlign;

  @HostBinding('attr.data-text-align-tablet')
  @Input()
  textAlignTablet?: TextAlign;

  @HostBinding('attr.data-text-align-desktop-small')
  @Input()
  textAlignDesktopSmall?: TextAlign;

  @Input() textDecoration?: 'underline' | 'overline' | 'line-through';
  @Input() textTransform?: TextTransform;

  @Input() weight = FontWeight.NORMAL;
  @Input() weightTablet?: FontWeight;
  @Input() weightDesktop?: FontWeight;

  /** Defines the HTML node it will use e.g (<p>, <span>, <strong>)
   *  Note: This is for semantics usage which defaults to a <p> tag
   */
  @HostBinding('attr.data-type')
  @Input()
  fontType = FontType.PARAGRAPH;

  /**
   * Default false, true will parse the \n, <br> tags in the string to create a new line
   * Will break the line when maxLines=false, or maxLines=true and readmore clicked
   */
  @Input() displayLineBreaks = false;

  /*
   * Maximum number of lines of text to display before truncating
   */
  @Input()
  @HostBinding('attr.data-max-lines')
  maxLines?: number;

  /*
   * Paired with [maxLines].
   * Displays "Read more" link or icon.
   */
  @Input()
  @HostBinding('attr.data-read-more')
  readMore = ReadMore.NONE;

  @Input() readMoreColor?: ReadMoreColor;

  /**
   * Paired with [maxLines].
   * Limit the height to prevent UI flickering during truncation in initialization.
   * This is an optional field when maxLines is set. However, it is recommended when using TextSize.INHERIT
   * to supply a custom value depending on the text size and line height we inherit from.
   */
  @Input()
  @HostBinding('style.max-height')
  maxHeight?: string;

  @HostBinding('style.overflow')
  overflow: string;

  @Output()
  expand = new EventEmitter<boolean>();

  private maxLinesHelper: MaxLinesHelper;
  private container: HTMLElement;
  private windowSize: { width?: number; height?: number } = {};
  private originalContainer: Node;
  private isInTransition: boolean;
  private isExpanded: boolean;
  private subscriptions = new Subscription();
  private animationRequestId: number | undefined = undefined;

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    private obs: ContentObserver,
    @SkipSelf() protected renderer: Renderer2,
    private timeUtils: TimeUtils,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    // Set the max height to prevent UI flickering before the text is truncated.
    if (this.maxLines && !this.maxHeight) {
      // FIXME: T267853 - share CSS variables with JS
      let lineHeightUi: number;
      let fontSizeUi: number;

      switch (this.size) {
        case TextSize.SMALL:
          lineHeightUi = 1.5;
          fontSizeUi = 16;
          break;
        case TextSize.MARKETING_SMALL:
          lineHeightUi = 1.5;
          fontSizeUi = 18;
          break;
        case TextSize.XXSMALL:
          lineHeightUi = 1.43;
          fontSizeUi = 14;
          break;
        default:
          lineHeightUi = 1.43;
          fontSizeUi = 14;
      }

      const readMoreLinkOffset = this.readMore === ReadMore.LINK ? 1 : 0;

      this.maxHeight = `${
        (this.maxLines + readMoreLinkOffset) * lineHeightUi * fontSizeUi
      }px`;
      this.overflow = 'hidden';
    }
  }

  ngAfterViewInit(): void {
    if (this.maxLines && isPlatformBrowser(this.platformId)) {
      [this.container] = this.element.nativeElement.children;
      this.subscriptions.add(
        this.ngZone.onStable
          .asObservable()
          .pipe(
            filter(() => !!this.container.scrollHeight),
            take(1),
          )
          .subscribe(() => {
            this.truncate();
          }),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.maxLines &&
      isPlatformBrowser(this.platformId) &&
      this.container &&
      'maxLines' in changes
    ) {
      this.truncate();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.animationRequestId) {
      cancelAnimationFrame(this.animationRequestId);
    }
  }

  destroyListeners(): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  truncate(): void {
    if (!isDefined(this.maxLines)) {
      return;
    }

    this.isExpanded = false;
    this.destroyListeners();
    [this.container] = this.element.nativeElement.children;
    // save original content
    this.originalContainer = this.container.cloneNode(true);
    this.maxLinesHelper = new MaxLinesHelper(this.container, isTruncated => {
      // The container has been truncated or restored. The result of either
      // actions require a transition period when we are in ReadMore.ICON mode.
      if (this.readMore === ReadMore.ICON) {
        this.isInTransition = true;
      }

      if (!isTruncated) {
        this.isInTransition = true;
        this.isExpanded = true;
        this.renderer.setStyle(
          this.element.nativeElement,
          'max-height',
          'none',
        );

        this.restoreContainer();
      }

      this.expand.emit(!isTruncated);

      // This indicates that the transition is completed.
      this.animationRequestId = requestAnimationFrame(() => {
        this.isInTransition = false;
      });
    });

    // Check if the text content fits within the container.
    // Truncate text if it exceeds the maximum line limit.
    // Append a "Read more" link or toggle icon to the end of the text block if specified.
    if (this.readMoreColor === ReadMoreColor.LIGHT) {
      this.maxLinesHelper.truncate(
        this.maxLines,
        this.readMore,
        this.color,
        ReadMoreColor.LIGHT,
      );
    } else {
      this.maxLinesHelper.truncate(this.maxLines, this.readMore, this.color);
    }

    // listen for changes of content
    this.subscriptions.add(
      this.obs
        .observe(this.container)
        .pipe(filter(() => !!this.container.scrollHeight))
        .subscribe(() => {
          // do nothing if the mutation is the result of the element expanding
          if (!this.isInTransition) {
            // Remove read more button before re-truncation
            this.maxLinesHelper.removeReadMoreButton();
            this.truncate();
          }
        }),
    );

    // listen for resize events
    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(this.timeUtils.rxDebounceTime(250))
        .subscribe(() => {
          // make sure the element is displayed
          if (
            this.container &&
            (this.container.offsetWidth ||
              this.container.offsetHeight ||
              this.container.getClientRects().length)
          ) {
            const newSizes = {
              width: window.innerWidth,
              height: window.innerHeight,
            };
            // only do something if the actual window size has changed
            if (
              this.windowSize.width !== newSizes.width ||
              this.windowSize.height !== newSizes.height
            ) {
              this.windowSize = newSizes;
              if (!this.isExpanded) {
                this.reTruncate();
              }
            }
          }
        }),
    );
  }

  private restoreContainer(): void {
    let lastIndex = 0;
    const originalChildNodes = this.originalContainer.childNodes;
    this.container.childNodes.forEach(childNode => {
      if (
        originalChildNodes.length <= lastIndex ||
        childNode.nodeType === Node.COMMENT_NODE
      ) {
        return;
      }

      const newChild = originalChildNodes[lastIndex].cloneNode(true);
      this.container.replaceChild(newChild, childNode);
      lastIndex++;
    });
    for (let i = lastIndex + 1; i < originalChildNodes.length; i++) {
      this.container.appendChild(originalChildNodes[i].cloneNode(true));
    }
  }

  private reTruncate(): void {
    // Restore the original content.
    this.restoreContainer();
    // Perform truncation again.
    this.truncate();
  }
}
